<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Chapel Summary Review</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="mr-2">
            {{ termText }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card style="max-height:600px">
          <v-list>
            <v-list-item v-if="status !== 'Approved'" :disabled="term === ''" @click="term = ''">
              <v-list-item-title>All Terms</v-list-item-title>
              <v-divider></v-divider>
            </v-list-item>
            <v-list-item v-for="{ term: option, text, count } in termOptions" :key="option" :disabled="option === term" @click="term = option">
              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
                <v-list-item-subtitle>{{ count }} summaries</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            Status: {{ status }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="option in statusOptions" :key="option" :disabled="option === status" @click="status = option">
            <v-list-item-title>Status: {{ option }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-data-table :items="summaries" :headers="headers" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }" :server-items-length="summaryCount" @update:options="updateOptions">
      <template v-slot:item.action="{ item }">
        <v-btn @click="selectForReview(item)">Review Summary</v-btn>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ stringFormatDate(item.createdAt) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ stringFormatDate(item.date) }}
      </template>
    </v-data-table>
    <review-summary ref="dialog" :selected="selected" @updated="afterReview"></review-summary>
  </v-card>
</template>
<script>
import ReviewSummary from '@/components/chapel/reviewSummary'
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    ReviewSummary
  },
  setup (props, { root }) {
    const status = ref('Pending')
    const statusOptions = ref(['Pending', 'Rejected', 'Approved'])
    const term = ref('')
    const termText = computed(() => {
      if (term.value === '') {
        return 'All Terms'
      } else {
        const temp = termOptions.value.filter(({ term: option }) => option === term.value)
        if (temp.length > 0) {
          return temp[0].text
        }
      }
      return '**Error**'
    })
    const termOptions = ref([])
    const summaries = ref([])
    const summaryCount = ref(0)
    const headers = ref([
      { text: 'Banner ID', value: 'bannerId' },
      { text: 'Name', value: 'name' },
      { text: 'Term', value: 'termText' },
      { text: 'Submitted Date', value: 'createdAt' },
      { text: 'Chapel Date', value: 'date' },
      { text: 'Chapel Speaker', value: 'speaker' },
      { text: 'Review', value: 'action' }
    ])

    const sumService = root.$feathers.service('chapel/summary')
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: []
    })

    function updateOptions (opt) {
      options.value = opt
      loadSummaries()
    }

    async function loadSummaries () {
      const { page, itemsPerPage, sortBy, sortDesc } = options.value
      const $sort = {}
      for (let i = 0; i < sortBy.length; i++) {
        if (sortBy[i] === 'termText') $sort.term = sortDesc[i] ? -1 : 1
        else $sort[sortBy[i]] = sortDesc[i] ? 1 : -1
      }
      if (sortBy.length === 0) {
        $sort.createdAt = 1
      }
      summaries.value = summaries.value.map(() => { return { a: 1 } })
      const $match = { status: status.value }
      if (term.value !== '') {
        $match.term = term.value
      } else if (status.value === 'Approved') {
        term.value = termOptions.value[termOptions.value.length - 1].term
        $match.term = term.value
      }
      const aggregate = [
        { $match },
        {
          $lookup: {
            from: 'Chapel-Students',
            localField: 'user',
            foreignField: '_id',
            let: { 'chapel': '$chapel' },
            as: 'student',
            pipeline: [
              { $unwind: { path: '$credits', includeArrayIndex: 'index' } },
              { $match: { $expr: { $eq: [ '$credits.chapel', '$$chapel' ] } } }
            ]
          }
        },
        {
          $lookup: {
            from: 'Chapel-Schedule',
            localField: 'chapel',
            foreignField: '_id',
            as: 'chapel'
          }
        },
        {
          $project: {
            pidm: 1,
            term: 1,
            summary: 1,
            status: 1,
            createdAt: 1,
            student: { '$last': '$student' },
            chapel: { '$last': '$chapel' }
          }
        },
        {
          $project: {
            block: 'a',
            pidm: 1,
            term: 1,
            termText: {
              $cond: [
                { $eq: [{ $substrBytes: ['$term', 4, 2] }, '05'] },
                { $concat: ['Spring ', { $substrBytes: ['$term', 0, 4] }] },
                { $concat: ['Fall ', { $substrBytes: ['$term', 0, 4] }] }
              ]
            },
            summary: 1,
            status: 1,
            createdAt: 1,
            bannerId: '$student.bannerId',
            name: { '$concat': [ '$student.name.last', ', ', '$student.name.preferred' ] },
            student: '$student._id',
            index: '$student.index',
            date: '$chapel.date',
            speaker: '$chapel.speaker',
            title: '$chapel.title',
            chapel: '$chapel._id',
            creditValue: '$chapel.credits.Summary'
          }
        },
        { $sort },
        { $skip: (page - 1) * itemsPerPage },
        { $limit: itemsPerPage }
      ]
      try {
        const { total } = await sumService.find({ query: { ...$match, $limit: 0 } })
        summaryCount.value = total
        const { data } = await sumService.find({ query: { aggregate } })
        summaries.value = data
      } catch (e) {
        root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error loading summaries: ' + e })
      }
    }

    const selected = ref({})
    const dialog = ref(null)
    function selectForReview (obj) {
      selected.value = obj
      root.$nextTick(() => { dialog.value.activate() })
    }

    async function loadTerms () {
      // Load unique terms that have the given status
      const aggregate = [
        { $match: { status: status.value } },
        { $group: { _id: '$term', count: { $sum: 1 } } },
        { $sort: { '_id': 1 } }
      ]
      const { data } = await root.$feathers.service('chapel/summary').find({ query: { aggregate } })
      termOptions.value = data.map(({ _id: term, count }) => {
        const text = (term.substring(4) === '05' ? 'Spring ' : 'Fall ') + term.substring(0, 4)
        return { term, text, count }
      })
    }
    watch(status, async () => {
      await loadTerms()
      loadSummaries()
    })
    watch(term, () => {
      loadSummaries()
    })

    function afterReview () {
      loadSummaries()
    }

    return {
      status,
      statusOptions,
      term,
      termText,
      termOptions,
      summaries,
      summaryCount,
      headers,
      updateOptions,
      loadSummaries,
      selected,
      dialog,
      selectForReview,
      afterReview,
      stringFormatDate
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Chapel Summary Review")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2"},on),[_vm._v(" "+_vm._s(_vm.termText)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-card',{staticStyle:{"max-height":"600px"}},[_c('v-list',[(_vm.status !== 'Approved')?_c('v-list-item',{attrs:{"disabled":_vm.term === ''},on:{"click":function($event){_vm.term = ''}}},[_c('v-list-item-title',[_vm._v("All Terms")]),_c('v-divider')],1):_vm._e(),_vm._l((_vm.termOptions),function(ref){
var option = ref.term;
var text = ref.text;
var count = ref.count;
return _c('v-list-item',{key:option,attrs:{"disabled":option === _vm.term},on:{"click":function($event){_vm.term = option}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(count)+" summaries")])],1)],1)})],2)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" Status: "+_vm._s(_vm.status)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.statusOptions),function(option){return _c('v-list-item',{key:option,attrs:{"disabled":option === _vm.status},on:{"click":function($event){_vm.status = option}}},[_c('v-list-item-title',[_vm._v("Status: "+_vm._s(option))])],1)}),1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.summaries,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] },"server-items-length":_vm.summaryCount},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.selectForReview(item)}}},[_vm._v("Review Summary")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stringFormatDate(item.createdAt))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stringFormatDate(item.date))+" ")]}}])}),_c('review-summary',{ref:"dialog",attrs:{"selected":_vm.selected},on:{"updated":_vm.afterReview}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }